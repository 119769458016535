import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import { Switch, FormGroup, FormControlLabel, makeStyles } from "@material-ui/core";
import lightBackground from "../src/assets/wa-background.png";
import darkBackground from "../src/assets/wa-background-dark.png";

const useStyles = makeStyles(() => ({
    switch: {
        margin: "2px",
        position: 'absolute', right: 0
    },
}));

const App = () => {
  const [locale, setLocale] = useState();
  const [checked, setChecked] = React.useState(false);
  const classes = useStyles();

  const lightTheme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8",
        },
      },
      palette: {
        primary: { main: "#6B62FE" },
        secondary: { main: "#F50057" },
        toolbar: { main: "#6B62FE" },
        menuItens: { main: "#ffffff" },
        sub: { main: "#ffffff" },
        toolbarIcon: { main: "#ffffff"},
        divide: { main: "#E0E0E0" },
        text: {
          primary: "#333333",
          secondary: "#6B62FE",
        },
      },
      backgroundImage: `url(${lightBackground})`,
    },
    locale
  );

  const darkTheme = createTheme(
    {
      overrides: {
        MuiCssBaseline: {
          '@global': {
            body: {
              backgroundColor: "#080d14",
            }
          }
        }
      },
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#52d869",
        },
      },
      palette: {
        primary: { main: "#52d869" },
        secondary: { main: "#ff9100" },
        toolbar: { main: "#52d869" },
        menuItens: { main: "#181d22" },
        sub: { main: "#181d22" },
        toolbarIcon: { main: "#52d869"},
        divide: { main: "#080d14" },
        background: {
          default: "#080d14",
          paper: "#181d22",
        },
        text: {
          primary: "#52d869",
          secondary: "#ffffff",
        },
      },
      backgroundImage: `url(${darkBackground})`,
    },
    locale
  );

  const [theme, setTheme] = useState("light");
    
    const themeToggler = () => {
        theme === "light" ? setTheme("dark") : setTheme("light");
    };
    
    const handleChange = (event) => {
        setChecked(event.target.checked);
        if(checked === false)
        {
            themeToggler();
        }
        else if (checked === true ){
            themeToggler();
        }
    };

    useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale = i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <Routes />
      <FormGroup row className={classes.switch}>
        <FormControlLabel control={
            <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            />}label="Dark Mode" />
            </FormGroup>
            </ThemeProvider>
          );
    };
export default App;